import { Container } from "@chakra-ui/layout";
import { Flex } from "@chakra-ui/react";
import { getImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../../components/Layout";
import PageHeadingSection from "../../components/PageHeadingSection";
import Card from "../../components/Card";
import { graphql } from "gatsby";

export default function Trainings({ data }) {
  const trainingImages = data.allFile.nodes;
  return (
    <Layout>
      <PageHeadingSection title={"Trainings"} />
      <Container maxW={"7xl"} py={5}>
        <Flex wrap={"wrap"} justifyContent={{ base: "center" }}>
          {trainingImages.map((e, i) => (
            <Card
              title={e.name}
              path={e.name.toLowerCase().replaceAll(" ", "-")}
              key={i}
              imgData={getImage(e)}
            />
          ))}
        </Flex>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query TrainingImages {
    allFile(
      filter: { relativeDirectory: { eq: "trainings" } }
      sort: { fields: name }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  }
`;

// const CATAGORIES = [
//   "Lead Auditor",
//   "Internal Auditor",
//   "Awareness",
//   "Advance Implementation",
//   "Soft Skill",
// ];
